import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Button, Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material';
// hooks
// import useOffSetTop from '../../../hooks/useOffSetTop';
// import useResponsive from '../../../hooks/useResponsive';
// utils
import cssStyles from '../../../utils/cssStyles';
// config
import { HEADER, NAVBAR } from '../../../config';
// components
import Logo from '../../../components/Logo';
import Iconify from '../../../components/Iconify';
import { useSelector } from '../../../redux/store';
import conObj from '../../../utils/connection-assistant';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ theme }) => ({
  // ...cssStyles(theme).bgBlur({ opacity: 0 }),
  boxShadow: 'none',
  paddingTop: 10,
  paddingLeft: 10,
  paddingRight: 10,
  backgroundColor: 'transparent !important',
  background: 'transparent',
  height: HEADER.MOBILE_HEIGHT,
  transition: theme.transitions.create(['width', 'height'], {
    duration: theme.transitions.duration.shorter,
  }),
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {};

function buildWhatsappSupportUrl(phoneNum) {
  if (!phoneNum) return null;

  return `https://api.whatsapp.com/send?phone=${phoneNum}`;
}

export default function DashboardHeader() {
  const { title, isSubPage } = useSelector((x) => x.pageTitle);

  const [liveSupportWhatsappPhoneNumber, setLiveSupportWhatsappPhoneNumber] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    getSettings();
  }, []);

  const getSettings = () => {
    conObj.get('Setting').then((res) => {
      if (res.status === 200) {
        // fPayPaymentGatewayEnabled,
        // implementationType,
        // liveSupportWhatsappPhoneNumber,
        // manualBankingEnabled,
        // referralCommissionFirstLoan,
        // referralCommissionSubsequentLoan,
        // surePay88PaymentGatewayEnabled,
        // vPay88PaymentGatewayEnabled,
        sessionStorage.setItem('appSettings', JSON.stringify(res.data));
        setLiveSupportWhatsappPhoneNumber(res.data.liveSupportWhatsappPhoneNumber);
      }
    });
  };

  return (
    <RootStyle>
      <Toolbar sx={{ px: 0 }}>
        {isSubPage && (
          <IconButton onClick={() => navigate(-1)} sx={{ mr: 2, color: '#ea3c30' }}>
            <Iconify icon="mdi:arrow-left" />
          </IconButton>
        )}
        <Typography variant="h4" sx={{ color: '#ea3c30', fontWeight: 400 }}>
          {title || ''}
        </Typography>
        <Box sx={{ flexGrow: 1 }} />

        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <a
            href={liveSupportWhatsappPhoneNumber ? buildWhatsappSupportUrl(liveSupportWhatsappPhoneNumber) : {}}
            target="_blank"
            rel="noreferrer"
          >
            <Iconify icon={'fluent:person-support-20-regular'} sx={{ width: '24px', height: '24px' }} color="#ea3c30" />
            {/* <img
              src={require('../../../assets/customer-support.png')}
              alt="Customer Suppoer"
              style={{ width: '24px', height: '24px' }}
            /> */}
            {/* <IconButton
              color="primary"
              onClick={() =>
                liveSupportWhatsappPhoneNumber
                  ? window.open(buildWhatsappSupportUrl(liveSupportWhatsappPhoneNumber), '_blank', 'noreferrer')
                  : {}
              }
            >
              <Iconify icon="bx:support" />
            </IconButton> */}
          </a>
        </Stack>
      </Toolbar>
    </RootStyle>
  );
}
