import { memo } from 'react';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import { Container, AppBar, Stack, Toolbar, Grid, Typography, ListItemButton } from '@mui/material';
// config
import { HEADER } from '../../../config';
// components
// import { NavSectionHorizontal } from '../../../components/nav-section';
//
import navConfig from './NavConfig';
import useLocales from '../../../hooks/useLocales';
import { getActive } from '../../../components/nav-section';

// ----------------------------------------------------------------------

// const RootStyle = styled(AppBar)(({ theme }) => ({
//   transition: theme.transitions.create('top', {
//     easing: theme.transitions.easing.easeInOut,
//     duration: theme.transitions.duration.shorter,
//   }),
//   width: '100%',
//   position: 'fixed',
//   zIndex: theme.zIndex.appBar,
//   padding: theme.spacing(1, 0),
//   boxShadow: theme.customShadows.z8,
//   top: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
//   backgroundColor: theme.palette.background.default,
// }));

const DashboardItem = styled(ListItemButton, {
  shouldForwardProp: (prop) => prop !== 'isActive',
})(({ isActive, theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'capitalize',
  ...(isActive && {
    color: '#fff',
  }),
}));

const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: HEADER.MOBILE_HEIGHT,
  position: 'relative',
  backgroundColor: theme.palette.grey[800],
}));

// ----------------------------------------------------------------------

function NavbarHorizontal() {
  const { translate } = useLocales();
  const { pathname } = useLocation();
  return (
    <StyledAppBar>
      <Toolbar sx={{ p: 0 }}>
        <Grid container>
          {navConfig.map((nav, idx) => (
            <Grid item xs={3} key={idx}>
              <DashboardItem
                component={RouterLink}
                to={nav.path}
                isActive={getActive(nav.path, pathname)}
                sx={{ color: '#fff' }}
              >
                {nav.icon}
                <Typography sx={{ color: getActive(nav.path, pathname) ? '#ea3c30' : '#fff' }} variant="caption">
                  {translate(nav.title)}
                </Typography>
              </DashboardItem>
            </Grid>
          ))}
        </Grid>
      </Toolbar>
    </StyledAppBar>
  );
}

export default memo(NavbarHorizontal);
